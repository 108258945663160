import React, { useEffect, useState } from 'react'
import { intl } from 'src/constants'
import Loading from 'components/atoms/Loading'
import { navigate } from 'gatsby'

const NotFoundPage = ({ location }) => {
  const [isLoad, setLoad] = useState(true)

  if (location.pathname.startsWith('/column/')) {
    navigate(location.pathname.replace('column', 'article'))
    return <Loading />
  }
  if (location.pathname.startsWith('/report/')) {
    navigate(location.pathname.replace('report', 'article'))
    return <Loading />
  }
  if (location.pathname.startsWith('/interview/')) {
    navigate(location.pathname.replace('interview', 'article'))
    return <Loading />
  }

  if (location.pathname.startsWith('/columns')) {
    navigate(location.pathname.replace('columns', 'tags/columns'))
    return <Loading />
  }

  if (location.pathname.startsWith('/reports')) {
    navigate(location.pathname.replace('reports', 'tags/reports'))
    return <Loading />
  }

  if (location.pathname.startsWith('/interviews')) {
    navigate(location.pathname.replace('interviews', 'tags/interviews'))
    return <Loading />
  }

  useEffect(() => {
    setLoad(false)
  }, [])

  return isLoad ? (
    <Loading />
  ) : (
    <div className="not_found">
      <h2>{intl.getMessage('not_found')}</h2>
    </div>
  )
}

export default NotFoundPage
